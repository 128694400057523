import React from "react"
import styled from "styled-components"

const FooterContainer = styled.footer``

const Copyright = styled.p`
  text-align: center;
  font-size: 0.8rem;
  margin: 2rem 0;

  & > a {
    margin: 0 1rem;
  }
`

const Footer = () => (
  <FooterContainer>
    <Copyright>
      <a href="https://github.com/hosso">GitHub</a>
      <a href="https://status.hosso.co">Status</a>
    </Copyright>
  </FooterContainer>
)

export default Footer
